<template>
  <div class="article">
    <div class="aside_management_mode regist_mode">
      <div class="head">
        <h5>세부정보</h5>
        <!-- <button class="excel_btn">엑셀 저장</button> -->
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div>
            <div class="input_text">
              <label
                class="require"
                :class="{
                  base_info: !checkValid && baseInfo,
                }"
                >공정명</label
              >
              <input
                type="text"
                placeholder="공정명"
                required
                v-model="newData.name"
              />
            </div>
          </div>

          <div>
            <div class="input_text">
              <label>설비위치</label>
              <input
                type="text"
                placeholder=""
                :value="
                  getMachineInfo(
                    newData.r_process_machine_list.length > 0
                      ? newData.r_process_machine_list
                      : '연동되지 않음',
                  )
                "
                readonly
              />
              <!-- :value="getMachineInfo(newData.machine_id).machine_location" -->
            </div>
          </div>
          <div class="input_text">
            <label>공정검사</label>
            <my-local-selectric
              v-if="showSelectric"
              :id="'verificationSelect'"
              :options="processVerificationOptions"
              :watch="newData.process_verification_id"
              @changeValue="
                $event => {
                  if ($event == 'new') {
                    showModal = true;
                  } else {
                    newData.process_verification_id = $event;
                  }
                }
              "
            >
            </my-local-selectric>
          </div>
          <div>
            <div class="input_text add">
              <label>연동설비</label>
              <div>
                <div>
                  <button
                    type="button"
                    class="btn_ledger"
                    @click="showProcessSearch = true"
                  >
                    연동설비 선택
                  </button>
                  <draggable
                    class="selected_items_wrap"
                    delay-on-touch-only="true"
                    :list="newData.r_process_machine_list"
                    delay="80"
                  >
                    <!-- <transition-group
                      name="selected"
                      mode="in-out"
                      tag="ul"
                      type="transition"
                      class="selected_items_wrap"
                      v-if="filterProcess.length > 0"
                    > -->
                    <div
                      class="selected_item"
                      v-for="(item, index) in newData.r_process_machine_list"
                      :key="item.id"
                    >
                      <span>{{
                        findInfoFromId(machines, item.machine_id).name
                      }}</span>
                      <button
                        type="button"
                        class="delete_item"
                        @click.prevent.stop="deleteManagementMachine(index)"
                      ></button>
                    </div>
                    <!-- </transition-group> -->
                  </draggable>
                </div>
              </div>
            </div>
          </div>
          <div class="input_checkbox">
            <label for="checkbox2" class="label_txt">포장공정</label>
            <label
              for="checkbox2"
              class="chk_box"
              :class="newData.package_process_yn ? 'active' : ''"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox2"
              @click="newData.package_process_yn = !newData.package_process_yn"
            />
          </div>
          <div class="btn_wrap">
            <button type="button" class="btn_sub2" @click="submitForm()">
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
    <new-verification
      v-if="showModal"
      :verification_type_id="2"
      @onclose="showModal = false"
    ></new-verification>
    <machine-search
      v-if="showProcessSearch"
      :ignore_list="newData.r_process_machine_list.map(x => x.machine_id)"
      @onclose="showProcessSearch = false"
      @onselect="pushFilterProcess($event)"
    ></machine-search>
  </div>
</template>

<script>
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import NewVerification from '@/layouts/components/NewVerification.vue';
import MachineSearch from '@/layouts/components/search-popup/MachineSearch';
import draggable from 'vuedraggable';
// import { clone } from '@/utils/func';
export default {
  mixins: [ModalMixin, SpinnerMixin, FETCH_MIXIN],
  data() {
    return {
      showSelectric: false,
      showModal: false,
      baseInfo: false,
      showProcessSearch: false,
    };
  },
  components: {
    draggable,
    MyLocalSelectric,
    NewVerification,
    MachineSearch,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromProcessMultiPage',
      newData: 'getNewDataFromProcessMulti',
      machine_options: 'getMachinesForSelectric',
      machines: 'getMachines',
      selectedIndex: 'getSelectedIndexFromProcessMulti',
      process: 'getProcessMulti',
      processVerificationOptions:
        'getSeqVerificationForSelectricFromQualityManagement',
      verifications: 'getBaseVerificationFromQualityManagementNotSort',
      filterProcess: 'getFilterProcessArrayFromReportProcess',
    }),
    checkValid() {
      if (
        this.newData == undefined ||
        this.newData == null ||
        this.newData.name == undefined ||
        this.newData.name == null ||
        this.newData.name.trim() == ''
      ) {
        return false;
      } else {
        return true;
      }
    },
    validName() {
      const processListChk = this.lodash
        .clonedeep(this.process)
        .find(x => x.name == this.newData.name.trim());

      if (processListChk != undefined) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getMachineInfo(arr) {
      let hitValue = this.machines.find(x => x.id == arr[0].machine_id);
      return hitValue == undefined
        ? '연동되지 않음'
        : hitValue.machine_location;
    },
    pushFilterProcess(arg) {
      if (
        this.newData.r_process_machine_list.find(x => x.machine_id == arg.id) !=
        undefined
      ) {
        this.showProcessSearch = false;
        return;
      }
      this.newData.r_process_machine_list.push({
        machine_id: arg.id,
        process_id: null,
      });
      this.showProcessSearch = false;
    },
    deleteManagementMachine(index) {
      this.newData.r_process_machine_list.splice(index, 1);
    },
    async FETCHS(str) {
      this.showSpinner();
      await this.$store
        .dispatch(str)
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '자재 관련 정보 로드 중 오류',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async submitForm() {
      if (this.checkValid && !this.validName) {
        this.showSpinner();
        let payload = this.lodash.clonedeep(this.newData);
        payload.name = payload.name.trim();

        await this.$store
          .dispatch('INSERT_PROCESS_MULTI', payload)
          .then(() => {
            this.openOneButtonModal('등록 성공', '성공적으로 등록하였습니다.');
            this.baseInfo = false;
            this.$store.commit('setNewDataToProcessMulti', {
              name: '',
              detail: '',
              machine_id: null,
              package_process_yn: false,
              process_verification_id: null,
              visible: false,
              r_process_machine_list: [],
            });
            this.FETCHS('FETCH_PROCESS_MULTI');
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal('저장 중 에러', '원자재 저장 중 에러발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (!this.checkValid) {
        this.openOneButtonModal('수정 불가', '공정명은 필수 입력값 입니다.');
        this.baseInfo = true;
      } else if (this.validName) {
        this.openOneButtonModal(
          '등록 불가',
          `같은 이름의 공정이 등록이 되어있습니다.<br />다른 공정명을 등록해주세요.`,
        );
      }
    },
  },

  async created() {
    if (this.machine_options.length < 2) {
      await this.FETCHS('FETCH_MACHINE');
    }
    if (this.verifications.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_BASE_VERIFICATION', '공정검사');
    }

    this.showSelectric = true;
  },
};
</script>

<style></style>
