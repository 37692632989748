var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article"},[_c('div',{staticClass:"aside_management_mode regist_mode"},[_vm._m(0),_c('div',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('form',[_c('div',[_c('div',{staticClass:"input_text"},[_c('label',{staticClass:"require",class:{
                base_info: !_vm.checkValid && _vm.baseInfo,
              }},[_vm._v("공정명")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newData.name),expression:"newData.name"}],attrs:{"type":"text","placeholder":"공정명","required":""},domProps:{"value":(_vm.newData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newData, "name", $event.target.value)}}})])]),_c('div',[_c('div',{staticClass:"input_text"},[_c('label',[_vm._v("설비위치")]),_c('input',{attrs:{"type":"text","placeholder":"","readonly":""},domProps:{"value":_vm.getMachineInfo(
                  _vm.newData.r_process_machine_list.length > 0
                    ? _vm.newData.r_process_machine_list
                    : '연동되지 않음'
                )}})])]),_c('div',{staticClass:"input_text"},[_c('label',[_vm._v("공정검사")]),(_vm.showSelectric)?_c('my-local-selectric',{attrs:{"id":'verificationSelect',"options":_vm.processVerificationOptions,"watch":_vm.newData.process_verification_id},on:{"changeValue":function ($event) {
                if ($event == 'new') {
                  _vm.showModal = true;
                } else {
                  _vm.newData.process_verification_id = $event;
                }
              }}}):_vm._e()],1),_c('div',[_c('div',{staticClass:"input_text add"},[_c('label',[_vm._v("연동설비")]),_c('div',[_c('div',[_c('button',{staticClass:"btn_ledger",attrs:{"type":"button"},on:{"click":function($event){_vm.showProcessSearch = true}}},[_vm._v(" 연동설비 선택 ")]),_c('draggable',{staticClass:"selected_items_wrap",attrs:{"delay-on-touch-only":"true","list":_vm.newData.r_process_machine_list,"delay":"80"}},_vm._l((_vm.newData.r_process_machine_list),function(item,index){return _c('div',{key:item.id,staticClass:"selected_item"},[_c('span',[_vm._v(_vm._s(_vm.findInfoFromId(_vm.machines, item.machine_id).name))]),_c('button',{staticClass:"delete_item",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteManagementMachine(index)}}})])}),0)],1)])])]),_c('div',{staticClass:"input_checkbox"},[_c('label',{staticClass:"label_txt",attrs:{"for":"checkbox2"}},[_vm._v("포장공정")]),_c('label',{staticClass:"chk_box",class:_vm.newData.package_process_yn ? 'active' : '',attrs:{"for":"checkbox2"}},[_c('i',{staticClass:"fa fa-check"})]),_c('input',{attrs:{"type":"checkbox","id":"checkbox2"},on:{"click":function($event){_vm.newData.package_process_yn = !_vm.newData.package_process_yn}}})]),_c('div',{staticClass:"btn_wrap"},[_c('button',{staticClass:"btn_sub2",attrs:{"type":"button"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" 저장 ")])])])])]),(_vm.showModal)?_c('new-verification',{attrs:{"verification_type_id":2},on:{"onclose":function($event){_vm.showModal = false}}}):_vm._e(),(_vm.showProcessSearch)?_c('machine-search',{attrs:{"ignore_list":_vm.newData.r_process_machine_list.map(function (x) { return x.machine_id; })},on:{"onclose":function($event){_vm.showProcessSearch = false},"onselect":function($event){return _vm.pushFilterProcess($event)}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head"},[_c('h5',[_vm._v("세부정보")])])}]

export { render, staticRenderFns }